import React, { useEffect } from 'react';

import { Box } from '@core';
import { ImageCore, LOADING_OPTIONS } from '@components';
import { useIsClientSide } from '@hooks';
import headerFrame from '@images/deals/spring-animation/header-frame.png';
import eyeSclera from '@images/deals/spring-animation/eye-sclera.png';

import Pupils from './pupils';

const addEyeMovement = () => {
  const eyes = document.getElementById('eyes');
  document.addEventListener('mousemove', (evt) => {
    const pos = eyes.getBoundingClientRect();

    let x = -(pos.x + pos.width / 2 - evt.pageX) / 20;
    let y = -(pos.y + pos.height / 2 - evt.pageY) / 20;

    // setting limits to x and y axis so the eyes don't go too far off
    x = Math.min(Math.max(x, -25), 25);
    y = Math.min(Math.max(y, -15), 15);

    eyes.style.transform = `translateY(${y}px) translateX(${x}px)`;
  });
};

const AnimatedEyesImage = () => {
  const { isClientSide } = useIsClientSide();

  useEffect(() => {
    if (isClientSide) {
      addEyeMovement();
    }
  }, [isClientSide]);

  return (
    <React.Fragment>
      <ImageCore
        src={headerFrame}
        alt="Header"
        height={560}
        maxWidth="unset"
        loading={LOADING_OPTIONS.eager}
      />
      <Box position="relative" zIndex={-1} top={-449} left={165}>
        {isClientSide && <Pupils />}
      </Box>
      <ImageCore
        src={eyeSclera}
        alt="Sclera"
        zIndex={-2}
        height={231}
        top={110}
        left={138}
        position="absolute"
        loading={LOADING_OPTIONS.eager}
      />
    </React.Fragment>
  );
};

export default AnimatedEyesImage;
