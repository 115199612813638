import React from 'react';

const Pupils = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="186"
    height="217"
    fill="none"
    style={{ overflow: 'visible' }}
    id="eyes"
  >
    <g>
      <path
        fill="#575868"
        d="M36.785 56.89c11.46 0 20.75-9.312 20.75-20.798 0-11.486-9.29-20.798-20.75-20.798s-20.75 9.311-20.75 20.798c0 11.486 9.29 20.798 20.75 20.798Z"
      />
      <path
        fill="url(#a)"
        d="M36.785 47.328c6.19 0 11.21-5.03 11.21-11.236 0-6.205-5.02-11.236-11.21-11.236-6.191 0-11.21 5.03-11.21 11.236 0 6.205 5.019 11.236 11.21 11.236Z"
      />
      <path
        fill="#fff"
        d="M44.427 32.377a3.187 3.187 0 0 0 3.184-3.19 3.187 3.187 0 0 0-3.184-3.192 3.187 3.187 0 0 0-3.184 3.191 3.187 3.187 0 0 0 3.184 3.191ZM31.515 44.612a1.58 1.58 0 0 0 1.579-1.582 1.58 1.58 0 0 0-1.579-1.582 1.58 1.58 0 0 0-1.579 1.582 1.58 1.58 0 0 0 1.58 1.582Z"
      />
      <radialGradient
        id="a"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(11.2098 0 0 11.2355 36.785 36.092)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".01" stopColor="#3F4154" />
        <stop offset="1" stopColor="#31333A" />
      </radialGradient>
    </g>
    <g>
      <path
        fill="#575868"
        d="M81.758 163.633c17.943 0 32.489-14.579 32.489-32.563S99.701 98.507 81.758 98.507c-17.942 0-32.487 14.579-32.487 32.563s14.545 32.563 32.487 32.563Z"
      />
      <path
        fill="url(#b)"
        d="M81.759 148.666c9.696 0 17.556-7.878 17.556-17.596s-7.86-17.596-17.556-17.596c-9.696 0-17.556 7.878-17.556 17.596s7.86 17.596 17.556 17.596Z"
      />
      <path
        fill="#fff"
        d="M93.72 125.244c2.751 0 4.981-2.235 4.981-4.993a4.986 4.986 0 0 0-4.98-4.992 4.986 4.986 0 0 0-4.982 4.992 4.987 4.987 0 0 0 4.981 4.993ZM73.504 144.412a2.478 2.478 0 0 0 2.474-2.48 2.478 2.478 0 0 0-2.474-2.481 2.478 2.478 0 0 0-2.475 2.481 2.478 2.478 0 0 0 2.475 2.48Z"
      />
      <radialGradient
        id="b"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(17.5559 0 0 17.5963 81.759 131.07)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".01" stopColor="#3F4154" />
        <stop offset="1" stopColor="#31333A" />
      </radialGradient>
    </g>
    <g>
      <path
        fill="#575868"
        d="M166.511 38.32c10.466 0 18.95-8.503 18.95-18.993 0-10.49-8.484-18.994-18.95-18.994s-18.95 8.504-18.95 18.994 8.484 18.994 18.95 18.994Z"
      />
      <path
        fill="url(#c)"
        d="M166.511 29.591c5.656 0 10.241-4.595 10.241-10.264s-4.585-10.265-10.241-10.265c-5.655 0-10.24 4.596-10.24 10.265 0 5.669 4.585 10.264 10.24 10.264Z"
      />
      <path
        fill="#fff"
        d="M173.49 15.93a2.909 2.909 0 0 0 2.905-2.912 2.909 2.909 0 0 0-2.905-2.912 2.908 2.908 0 0 0-2.905 2.912c0 1.608 1.3 2.912 2.905 2.912ZM161.697 27.11c.798 0 1.444-.649 1.444-1.448a1.446 1.446 0 1 0-2.889 0c0 .8.647 1.448 1.445 1.448Z"
      />
      <radialGradient
        id="c"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(10.2409 0 0 10.2644 166.511 19.327)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".01" stopColor="#3F4154" />
        <stop offset="1" stopColor="#31333A" />
      </radialGradient>
    </g>
    <g>
      <path
        fill="#575868"
        d="M16.213 216.541c8.727 0 15.802-7.091 15.802-15.838 0-8.748-7.075-15.839-15.802-15.839S.411 191.955.411 200.703c0 8.747 7.075 15.838 15.802 15.838Z"
      />
      <path
        fill="url(#d)"
        d="M16.213 209.256c4.713 0 8.534-3.829 8.534-8.554 0-4.724-3.82-8.554-8.534-8.554-4.713 0-8.534 3.83-8.534 8.554 0 4.725 3.82 8.554 8.534 8.554Z"
      />
      <path
        fill="#fff"
        d="M22.032 197.872a2.427 2.427 0 0 0 2.424-2.43 2.427 2.427 0 0 0-2.424-2.43 2.427 2.427 0 0 0-2.425 2.43 2.427 2.427 0 0 0 2.425 2.43ZM12.2 207.191c.666 0 1.205-.541 1.205-1.208 0-.666-.54-1.207-1.204-1.207-.666 0-1.205.541-1.205 1.207 0 .667.54 1.208 1.204 1.208Z"
      />
      <radialGradient
        id="d"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="matrix(8.53434 0 0 8.55396 16.213 200.702)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".01" stopColor="#3F4154" />
        <stop offset="1" stopColor="#31333A" />
      </radialGradient>
    </g>
  </svg>
);

export default Pupils;
